<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h3>Media</h3>

                <v-btn
                        text
                        :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
                        outlined
                        class="btn btn-lg btn-secondary-main"
                        style="background:transparent;"
                >
                    <i class="fas fa-arrow-left"></i> Back
                </v-btn>
                <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
            </div>
        </div>

        <v-app v-if="error">
            <v-row>
                <v-col cols="12">
                    <v-alert
                            border="right"
                            colored-border
                            type="error"
                            elevation="2"
                    >Sorry, We Only Support pdf, docs, png, jpeg, ppt files
                    </v-alert>
                </v-col>
            </v-row>
        </v-app>

      <div class="row" v-if="loading">
        <div class="col-md-3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </div>
      </div>
      <div class=""  v-else>
        <v-row>
          <v-col cols="12">
            <div class="upload-control" v-show="images.length">
              <div class="row align-items-center">
                <div class="col-md-7">
                  <div class="row">
                    <div class="col col-md-2" v-for="(image, index) in images" :key="index">
                      <div class="images-preview" v-show="images.length" style="margin: 0;">
                        <div class="img-wrapper">
                          <img :src="image" :alt="`Image Uploader ${index}`"
                               style="max-width: 100%; display: block; margin: 0 auto;"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 text-right">
                  <v-btn
                      type="submit"
                      color="white--text v-btn theme--light elevation-2 v-size--large primary"
                      class="btn-save-popup"
                      text
                      @click="upload"
                      :loading="isBusy"
                  >Upload
                  </v-btn>
                </div>
              </div>
            </div>
            <div
                class="uploader"
                @dragenter="OnDragEnter"
                @dragleave="OnDragLeave"
                @dragover.prevent
                @drop="onDrop"
                :class="{ dragging: isDragging }"
            >

              <div>
                <i class="fa fa-cloud-upload"></i>
                <p>Drag your Files here</p>
                <div>OR</div>
                <div class="file-input">
                  <label for="file">Select a file</label>
                  <input type="file" id="file" @change="onInputChange" multiple/>
                </div>
              </div>

            </div>
          </v-col>
        </v-row>
        <v-app>

          <div class="row media-gallery text-center mt-5 justify-content-center" v-show="medias.length">
            <div class="col-md-2 m-1 preview-images" v-for="(image, index) in medias" :key="index">
              <progressive-img
                  v-if="checkFileType(image.title)"
                  :src="image.image_path.thumb"
                  aspect-ratio="1"
                  class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate
                                         color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </progressive-img>

              <img v-else-if="fileType(image.title)=='pdf'" src="@/assets/media/svg/files/pdf.svg"
                   :alt="`Image Uploader ${index}`"/>
              <img v-else-if="fileType(image.title)=='excel'" src="@/assets/media/svg/files/csv.svg"
                   :alt="`Image Uploader ${index}`"/>
              <img v-else-if="fileType(image.title)=='doc'" src="@/assets/media/svg/files/doc.svg"
                   :alt="`Image Uploader ${index}`"/>
              <img v-else src="@/assets/media/svg/files/xml.svg" :alt="`Image Uploader ${index}`"/>
              <div class="details">
                <span class="name">{{ image.title }}</span>
                <span
                    class="action-bar cursor-pointer mt-3 d-flex flex-wrap flex-row justify-content-center"
                >
                          <a :href="image.image_path.original" target="_blank" class="mr-3">
                          <i class="fas fa-eye blue-grey--text" title="View"></i>
                        </a>

                        <a href="#"
                           v-clipboard:copy="image.image_path.original"
                           v-clipboard:success="onCopy"
                           v-clipboard:error="onError"
                           @click.prevent="showUrl(image)">
                          <i class="fas fa-copy mr-3 light-green--text" title="Copy Url"></i>
                        </a>
                         <a href="#" @click.prevent="deleteImg(image.id)">
                          <i class="fas fa-trash red--text" title="Delete"></i>
                        </a>

                      </span>
              </div>
            </div>
            <!--                    <div class="add-btn-wrapper">-->
            <!--                      <div class="icon-wrap">-->
            <!--                        <label for="file">-->
            <!--                          <i class="fa fa-plus"></i>-->
            <!--                        </label>-->
            <!--                      </div>-->
            <!--                    </div>-->
          </div>
          <v-dialog scrollable v-model="dialog" max-width="500">
            <v-card>
              <v-card-title class="headline">Use this URL</v-card-title>

              <v-card-text v-if="url">{{ url }}</v-card-text>

              <v-card-actions></v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ text }}
            <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
        </v-app>
      </div>

    </div>
</template>

<script>
    import WebsiteMedia from "@/services/Websites/WebsiteMediaService";

    const WebsiteMediaService = new WebsiteMedia();
    export default {
        name: "website-media",
        components: {},
        data: () => ({
            isBusy: false,
            dialog: false,
            isDragging: false,
            dragCount: 0,
            files: [],
            images: [],
            error: false,
            absolute: true,
            overlay: false,
            medias: null,
            totalSize: null,
            page: null,
            pageCount: null,
            snackbar: false,
            text: "My timeout is set to 2000.",
            imageFiles: ["jpg", "jpeg", "png", "gif"],
            timeout: 2000,
            loading:true
        }),
        methods: {
            checkType(type) {
                if (type.includes("image")) {
                    return true;
                } else return false;
            },
            showUrl(image) {
                // console.log(image)
                this.url = image.image_path.original;
                this.dialog = true;
            },
            checkFileType(file) {
                let fileExtension = file.split(".")[file.split(".").length-1];
                let image = this.imageFiles.indexOf(fileExtension) > -1;
                return image;
            },
            fileType(file) {
                let fileExtension = file.split(".")[file.split(".").length-1];
                return fileExtension;
            },
            OnDragEnter(e) {
                e.preventDefault();

                this.dragCount++;
                this.isDragging = true;

                return false;
            },
            OnDragLeave(e) {
                e.preventDefault();
                this.dragCount--;

                if (this.dragCount <= 0) this.isDragging = false;
            },
            onInputChange(e) {
                const files = e.target.files;

                Array.from(files).forEach(file => this.addImage(file));
            },
            onDrop(e) {
                e.preventDefault();
                e.stopPropagation();

                this.isDragging = false;

                const files = e.dataTransfer.files;

                Array.from(files).forEach(file => this.addImage(file));
            },
            addImage(file) {
                const type = file.name.split(".")[1];
                if (file.type.match("image.*")) {
                    this.addFiles(file);
                } else if (type == "pdf") {
                    this.addFiles(file);
                } else if (type == "pptx") {
                    this.addFiles(file);
                } else if (type == "doc") {
                    this.addFiles(file);
                } else if (type == "docx") {
                    this.addFiles(file);
                } else if (type == "csv") {
                    this.addFiles(file);
                } else if (type == "xlsx") {
                    this.addFiles(file);
                } else if (type == "mp3") {
                    this.addFiles(file);
                } else if (type == "mp4") {
                    this.addFiles(file);
                } else {
                    // alert(`${file.name} is not an image`);
                    this.error = true;
                    // file.type.split('/')[1]
                    return;
                }
            },

            onCopy: function (e) {
                this.text = "You just copied URL ";
                this.snackbar = true;
            },
            onError: function (e) {
                this.text = "Failed to copy URL";
                this.snackbar = true;
            },
            addFiles(file) {
                this.error = false;
                this.files.push(file);

                const img = new Image(),
                    reader = new FileReader();

                reader.onload = e => this.images.push(e.target.result);

                reader.readAsDataURL(file);
            },
            deleteImg(id) {
                this.$confirm(
                    {
                        message: `Are you sure you want to delete this file permanently ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteMediaService.delete(this.siteUrl, id).then(res => {
                                    this.$snotify.success("Deleted");
                                    this.getMedia();
                                });
                            }
                        }
                    }
                )


            },
            getFileSize(size) {
                const fSExt = ["Bytes", "KB", "MB", "GB"];
                let i = 0;

                while (size > 900) {
                    size /= 1024;
                    i++;
                }

                return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
            },
            upload() {
                const formData = new FormData();
                this.isBusy = true;
                this.files.forEach(file => {
                    formData.append("file[]", file);
                    formData.append("size[]", file.size);
                    formData.append("name[]", file.name);
                    formData.append("type[]", file.name.split(".")[1]);
                });

                // alert("the total size uploaded is " + this.getFileSize(this.totalSize));
                WebsiteMediaService.create(this.siteUrl, formData).then(res => {
                    this.isBusy = false;
                    this.$snotify.success("Uploaded");
                    this.getMedia();
                    this.images = [];
                    this.files = [];
                });
            },
            getMedia(index = null) {
                WebsiteMediaService.paginate(this.siteUrl, index)
                    .then(response => {
                        this.medias = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.pageCount = response.data.meta.last_page;
                        this.loading=false;
                    })
                    .catch(error => {
                    });
            }
        },
        computed: {
            siteUrl() {
                return this.$route.params.domainname;
            }
        },
        mounted() {
            this.getMedia();
        }
    };
</script>

<style lang="scss" scoped>
    .uploader {
        width: 100%;
        background: #eef5ff;
        color: #777;
        //padding: 40px 15px 102px - 30px;
        text-align: center;
        border-radius: 10px;
        //border: 1px dashed #696969 !important;
        font-size: 17px;
        position: relative;

        &.dragging {
            background: #fff;
            color: #2196f3;
            border: 1px dashed #696969 !important;

            .file-input label {
                background: #2196f3;
                color: #fff;
            }
        }

        i {
            font-size: 20px;
        }

        .file-input {
            width: 175px;
            margin: auto;
            height: 65px;
            position: relative;

            label,
            input {
                background: #2196f3;
                color: #fff;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                padding: 10px;
                border-radius: 4px;
                margin-top: 7px;
                cursor: pointer;
            }

            input {
                opacity: 0;
                z-index: -2;
            }
        }

        .images-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 20px;
            margin-left: -10px;
            margin-right: -10px;
            padding: 0 10px;



            .add-btn-wrapper {
                width: 100% !important;
                border: 1px dashed;
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                justify-content: center;
                order: 0 !important;
            }

            .details {
                font-size: 12px;
                background: #fff;
                color: #000;
                display: flex;
                flex-direction: column;
                align-items: self-start;
                padding: 3px 6px;

                .name {
                    overflow: hidden;
                    height: 18px;
                    width: 100%;
                }
            }

            .img-wrapper {
                height: auto !important;

                img {
                    max-height: 150px;
                    object-fit: cover;
                }
            }
        }

        .action-bar {
            width: 100%;
        }

        .action-bar i {
            font-size: 15px;
        }

        .upload-control {
            position: relative;
            width: 88%;
            background: #3cbd81;
            bottom: 0;
            left: 0;
            padding: 10px;
            padding-bottom: 10px;
            padding-bottom: 4px;
            top: 10px;
            margin: 0 auto;

            button,
            label {
                background: #fff;
                border: 1px solid #7b7b7b;
                border-radius: 3px;
                color: #111;
                font-size: 13px;
                cursor: pointer;
            }

            label {
                padding: 2px 5px;
                margin-right: 10px;
            }
        }
    }
</style>
